import { ISiteConfiguration, IPage } from "@siterno/siter-web";
import {
  SiteConfigurationContext,
  GlobalStyle,
  useBlockRefs,
  BlocksContext,
  Layout,
  CrispChat,
} from "@parselno/ui";

import { AppProps } from "next/app";
import React, { ReactElement, ReactNode } from "react";
import { GlobalStyles } from "@parselno/ui";
import { ToastContainer } from "react-toastify";
import { NextPage } from "next";
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ pageProps, Component }: AppPropsWithLayout) => {
  const siteConfiguration: ISiteConfiguration = pageProps.siteConfiguration;
  const page: IPage = pageProps.page;
  const blockRefs = useBlockRefs(page?.content); // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);
  return (
    <>
      <SiteConfigurationContext.Provider value={siteConfiguration || {}}>
        <GlobalStyles />
        <GlobalStyle colorSchema={siteConfiguration?.colorSchema} />

        <BlocksContext.Provider
          value={{ blockRefs, blocks: page?.content || [] }}
        >
          {getLayout(<Component {...pageProps} />)}
        </BlocksContext.Provider>
        <ToastContainer theme="dark" />
        <CrispChat
          crispWebsiteId={siteConfiguration?.integrationCrispLiveChat}
        />
      </SiteConfigurationContext.Provider>
    </>
  );
};

export default MyApp;
